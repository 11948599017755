
.sk-spinner[data-v-7c25daee] {
  height: 33px;
  padding-top: 7px;
  text-align: left;
  margin: 0;
}


.sk-spinner[data-v-0fd5c47c] {
  height: 33px;
  padding-top: 7px;
  text-align: left;
  margin: 0;
}


.fa-check.active[data-v-aeb3b55c], .fa-user-circle-o.active[data-v-aeb3b55c] {
  color:green;
}
.fa-check.disabled[data-v-aeb3b55c], .fa-user-circle-o.disabled[data-v-aeb3b55c] {
  color:red;
}
td div.pretty[data-v-aeb3b55c] {
  margin:0;
}

